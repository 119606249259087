import * as React from "react"
import type { HeadFC } from "gatsby"

import "../styles/global.scss"



const IndexPage = () => {
  const email = 'hello@thehatpack.com';

  return (
    <>
      <main className="main">
        <img className="main-image-mobile" src="/static/images/asset.png" alt="asset" />

        <section className="info-container">
          <h1 className="title">
            EXCUSE OUR APPEARANCE WE ARE EVOLVING
          </h1>

          <p className="description">
            We are getting ready to offer you best-in-breed technology.
            In the meantime, reach out to <a href={`mailto:${email}`}>{email}</a>
            <img className="main-image" src="/static/images/asset.png" alt="asset" />
          </p>
        </section>
      </main>

      <footer className="footer">
        <section  className="footer-section">
          <h4 className="footer-title">ABOUT</h4>
          <p className="footer-description">
            Whether it's a rebranding, a website, video production, animation and everything in between,
            we wear many hats to find the one that's right for your brand.
          </p>

          <p className="footer-mail-description">Let's talk! -  <a className="footer-link" href={`mailto:${email}`}>{email}</a></p>
        </section>
      </footer>
    </>
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>Home Page</title>
